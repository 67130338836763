import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/login',
    name:'login',
    component:() => import('../views/LoginView.vue')
  },
  {
    //数据面板页
    path:'/DataDashboard',
    name:'dataDashboard',
    component:()=> import('../views/DataDashboard.vue')
  },
  {
    path:'/',
    redirect:'/login'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  base:'serMerWebAPP',
  routes
})

export default router
