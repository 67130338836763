import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import VueGoodTablePlugin from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'


const app = createApp(App)
installElementPlus(app)
app.use(VueGoodTablePlugin)




app.use(store).use(router).mount('#app')